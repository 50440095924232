import { RpcStatus } from "./api/common/data-contracts";
import { errorToast } from "./utils/toast/error-toast";

export enum ApiErrorCode {
    NOT_FOUND = 0,
    RESET_EMAIL_PERMISSION_DENIED = 100,
    INVALID_ARGUMENT = 101,
    USER_NOT_FOUND = 102,
    USER_IS_BANNED = 103,
    INVALID_PASSWORD = 104,
    TOKEN_IS_NOT_FOUND_OR_EXPIRED = 105,
    USER_ALREADY_EXISTS = 106,
    INVALID_TOKEN = 107,
    PASSWORD_IS_EXPIRED = 108,
    OTP_SECRET_IS_NOT_GENERATED_LOGIN_FIRST = 109,
    INVALID_OTP_PASSCODE = 110,
    SUB_ROLE_NOT_FOUND = 111,
    SUB_ROLE_EXISTS = 112,
    COMPANY_NAME_ALREADY_EXIST = 303,
    TAG_ALREADY_EXISTS = 413,
    GAME_ALREADY_EXISTS = 417,
    INTERNAL_SERVER_ERROR = 500,
    REPORT_PERMISSION_DENIED = 700,
    REPORT_FOLDER_EXISTS = 702,
    STANDARD_REPORT_EXISTS = 703,
    METRIC_IS_NOT_UNIQUE = 706,
    FOLDER_HAS_CHILD_REPORTS = 709,
    METRIC_USED_IN_REPORTS = 710,
    PERMISSION_DENIED = 800,
    INVALID_PROJECT_URLS = 401,
    INCONSISTENT_VENDOR_DATA = 429,
    REPORT_FILE_NO_FOUND = 1103,
    REPORT_EXPORT_PERMISSION_DENIED = 1300,
    REPORT_EXPORT_WRONG_STATUS = 1303,
}

export type OnApiError = (error?: RpcStatus) => Promise<boolean> | boolean;

export const handleApiError = async (
    { error }: { error?: RpcStatus },
    onError?: OnApiError,
) => {
    if (await onError?.(error)) {
        return;
    }

    switch (error?.code) {
        case ApiErrorCode.PERMISSION_DENIED:
            errorToast("Permission denied.");
            break;
        default:
            errorToast();
    }
};
