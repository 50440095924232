import { ACTIVE } from "lib/constants/status";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { NavLinkProps } from "./nav-link.interface";
import { LinkStyled, StyledLinkBlock } from "./nav-link.styled";

export const NavLink = ({
    to,
    label,
    isFullWidth,
    onClick,
}: NavLinkProps) => {
    const { asPath, isReady } = useRouter();

    const [className, setClassName] = useState("");

    useEffect(() => {
        if (isReady) {
            const linkPathname = new URL(to, location.href).pathname;

            const activePathname = new URL(asPath, location.href).pathname;

            const newClassName = linkPathname === activePathname ? ACTIVE : "";

            if (newClassName !== className) {
                setClassName(newClassName);
            }
        }
    }, [asPath, isReady, setClassName, className, to]);

    return (
        <StyledLinkBlock
            isFullWidth={isFullWidth}
            onClick={onClick}
        >
            <LinkStyled href={to} prefetch={false} className={className}>{label}</LinkStyled>
        </StyledLinkBlock>
    );
};

NavLink.displayName = "NavLink";
