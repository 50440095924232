import { CUSTOM_STYLES, SET_PATH_SCRIPT, ZENDESK_STYLES } from "./constants";

export const generatePageInfoIframeDoc = ({
    title,
    body,
}: {
    title?: string;
    body?: string;
}) => {
    return `
        <html>
            <body >
                <h1 id="article-title">${title}</h1>
                ${body || ""}
            </body>
            <style>
                ${ZENDESK_STYLES}
                ${CUSTOM_STYLES}
            </style>
            <script>
                ${SET_PATH_SCRIPT}
            </script>
        </html>
    `;
};
